import './BuilderV1Page.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';

import { Button } from '../Button';
import {
  BuilderV1PageContentType,
  GridSectionColumnContentType,
  GridSectionContentType,
  ProductsSectionContentType,
  ProductsSliderWithImageContentType,
  RoundedSliderContentType,
  SliderWithTextContentType,
  ThreeDimensionalSliderContentType,
} from '../contentTypes';
import { Icon } from '../Icon';
import { ProductTile } from '../ProductTile';
import { SliderButton } from '../SliderButton';
import { Page } from './Page';

const gridSectionColumnSizeConverter = (size: string): b2x.ColSize => {
  const sizeFound = size.split('/')[0];
  if (!sizeFound) {
    return '';
  }

  return parseInt(sizeFound) as b2x.ColSize;
};

const spaceBetweenToGapConverter = (spaceBetween?: string): b2x.Gap => {
  switch (spaceBetween) {
    case 'small':
      return 1;
    case 'medium':
      return 3;
    case 'large':
      return 5;
    default:
      return 0;
  }
};

const rowMarginSizeToSizeConverter = (marginSize?: string): b2x.MarginSize => {
  switch (marginSize) {
    case 'default':
      return 0;
    case 'large':
      return 3;
    case 'extraLarge':
      return 5;
    default:
      return 0;
  }
};

export const BuilderV1Page = () => {
  const page = b2x.usePage<BuilderV1PageContentType>();

  const body = page?.content?.body;
  return (
    <Page className={`builder-v1-page bg-${body?.backgroundColor}`} thingsToLoadBeforePageReady={[page]}>
      <b2x.Div display="flex" flexDirection="column" gap={5}>
        {body?.rows?.map((row) => {
          let component = null;
          if (row.component === 'gridSection') {
            component = <GridSection {...row.gridSection} />;
          }

          if (row.component === 'sliderWithText') {
            component = <SliderWithText {...row.sliderWithText} />;
          }

          if (row.component === 'roundedSlider') {
            component = <RoundedSlider {...row.roundedSlider} />;
          }

          if (row.component === 'products') {
            component = <ProductsSection {...row.products} />;
          }

          if (row.component === '3dSlider') {
            component = <ThreeDimensionalSlider {...row['3dSlider']} />;
          }

          if (row.component === 'productsSliderWithImage') {
            component = <ProductsSliderWithImage {...row.productsSliderWithImage} />;
          }

          return (
            <b2x.Div key={row.contentSectionId} marginY={rowMarginSizeToSizeConverter(row.rowMarginSize)}>
              {component}
            </b2x.Div>
          );
        })}
      </b2x.Div>
    </Page>
  );
};

interface ProductsSliderWithImageProps extends ProductsSliderWithImageContentType {}

const ProductsSliderWithImage = ({
  description,
  ids,
  img,
  mobileTitle,
  mobileTitleColor,
  subtitle,
  title,
}: ProductsSliderWithImageProps) => {
  return (
    <div className="products-slider-with-image">
      <b2x.Row>
        <b2x.Col display={{ lg: 'none' }} size={12}>
          <b2x.H3 className="text-uppercase fw-bold mb-0" textVariant={mobileTitleColor}>
            {mobileTitle}
          </b2x.H3>
          <h4 className="text-uppercase text-primary fw-semi-bold mb-4">{title}</h4>
        </b2x.Col>
        <b2x.Col className="before-product-slider col-md-6 col-lg-4 col-xxl-3 mb-4 mb-lg-0">
          <div className="before-product-slider-image">
            <b2x.ImageFromContentV1 {...img} fluid />
          </div>
          <div className="before-product-slider-content bg-black text-white p-4 ms-auto me-auto mt-n5">
            <p>
              <span className="fw-semi-bold">{subtitle}</span>
              <br />
              {description}
            </p>
          </div>
        </b2x.Col>
        <b2x.Col className="col-md-6 col-lg-8 col-xxl-9">
          <h3 className="d-none d-lg-block text-uppercase h1 text-primary fw-bold mb-4">{title}</h3>
          <b2x.Div
            className="slider-container"
            paddingStart={{
              lg: 4,
              xs: 0,
            }}
          >
            {ids?.length && ids.length > 0 && (
              <b2x.ProductsByIds ids={ids.map(({ id }) => id)}>
                {(products) => (
                  <b2x.EqualHeight>
                    <b2x.SwiperContext>
                      {({ navigationNextElRef, navigationPrevElRef }) => (
                        <b2x.Div alignItems="center" display="flex">
                          <b2x.Div display={{ lg: 'block', xs: 'none' }}>
                            <SliderButton direction="left" innerRef={navigationPrevElRef} size="large" />
                          </b2x.Div>
                          <b2x.Listing name="" products={products}>
                            <b2x.Swiper
                              breakpoints={{
                                lg: {
                                  slidesPerView: 2.15,
                                },
                                xl: {
                                  slidesPerView: 3.25,
                                },
                                xs: {
                                  slidesPerView: 1.15,
                                },
                              }}
                              loop
                              navigation={{ custom: true }}
                              slides={products?.map((product) => (
                                <ProductTile key={product.id} product={product} />
                              ))}
                              slidesPerView={1.2}
                              spaceBetween={20}
                              style={{ flex: 1 }}
                            />
                          </b2x.Listing>
                          <b2x.Div display={{ lg: 'block', xs: 'none' }}>
                            <SliderButton direction="right" innerRef={navigationNextElRef} size="large" />
                          </b2x.Div>
                        </b2x.Div>
                      )}
                    </b2x.SwiperContext>
                  </b2x.EqualHeight>
                )}
              </b2x.ProductsByIds>
            )}
          </b2x.Div>
        </b2x.Col>
      </b2x.Row>
    </div>
  );
};

interface RoundedSliderProps extends RoundedSliderContentType {}

const RoundedSlider = ({ slides }: RoundedSliderProps) => {
  return (
    <div className="rounded-slider">
      <b2x.Container>
        <b2x.SwiperContext>
          {({ swiper }) => (
            <b2x.Swiper
              breakpoints={{
                lg: {
                  creativeEffect: {
                    next: { translate: ['100%', 0, 0] },
                    prev: { translate: ['-100%', 0, 0] },
                  },
                  slidesPerView: 3,
                },
              }}
              centeredSlides
              creativeEffect={{
                limitProgress: 2,
                next: { scale: 0.6, translate: ['80%', 0, 0] },
                prev: { scale: 0.6, translate: ['-80%', 0, 0] },
              }}
              effect="creative"
              initialSlide={1}
              navigation={{ custom: true }}
              slides={slides?.map(({ asset, contentSectionId, icon, title }) =>
                // eslint-disable-next-line react/display-name
                ({ isActive }) => (
                  <b2x.Div
                    className="text-center position-relative"
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    justifyContent="center"
                    key={contentSectionId}
                  >
                    <span className="h4 px-1 px-lg-3 py-2 text-white m-0">{title}</span>
                    {asset && (
                      <b2x.Div
                        className={classNames('position-relative', {
                          'px-5': isActive,
                        })}
                      >
                        <b2x.Div paddingX={3}>
                          <b2x.AssetV1 className="w-100" {...asset} fluid />
                        </b2x.Div>
                        {isActive && (
                          <>
                            {!swiper?.isBeginning && (
                              <SliderButton
                                className="position-absolute"
                                direction="left"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => swiper?.slidePrev()}
                                size="small"
                                style={{ zIndex: 10 }}
                              />
                            )}
                            {!swiper?.isEnd && (
                              <SliderButton
                                className="position-absolute"
                                direction="right"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => swiper?.slideNext()}
                                size="small"
                                style={{ zIndex: 10 }}
                              />
                            )}
                          </>
                        )}
                      </b2x.Div>
                    )}
                    {icon && <b2x.NotResponsiveImageFromContent className="rounded-slider-icon" {...icon} fluid />}
                  </b2x.Div>
                )
              )}
              slidesPerView={1.3}
              wrapperClass="align-items-center"
            />
          )}
        </b2x.SwiperContext>
      </b2x.Container>
    </div>
  );
};

interface SliderWithTextProps extends SliderWithTextContentType {}

const SliderWithText = ({ assets, text, textColor }: SliderWithTextProps) => {
  return (
    <b2x.Div className="overflow-hidden slider-with-text">
      <b2x.Row>
        <b2x.Col
          size={{
            lg: 4,
            xs: 12,
            xxl: 3,
          }}
        >
          <b2x.Div
            alignItems="center"
            className="h-100"
            display="flex"
            paddingX={{
              lg: 5,
              xs: 3,
            }}
          >
            <b2x.H3 className={`text-${textColor}`}>{b2x.formatHtml(text)}</b2x.H3>
          </b2x.Div>
        </b2x.Col>
        <b2x.Col
          size={{
            lg: 8,
            xs: 12,
            xxl: 9,
          }}
        >
          <b2x.Div
            className="position-relative"
            marginTop={{
              lg: 0,
              xs: 3,
            }}
            paddingStart={{
              lg: 4,
              xs: 0,
            }}
          >
            <b2x.SwiperContext>
              {({ navigationNextElRef, navigationPrevElRef }) => (
                <>
                  <b2x.Swiper
                    breakpoints={{
                      sm: { slidesPerView: 2.2 },
                      xl: { slidesPerView: 2.5 },
                      xs: { slidesPerView: 1.2 },
                    }}
                    navigation={{ custom: true }}
                    slides={assets?.map((asset) => (
                      <b2x.AssetV1 className="w-100" key={asset.contentSectionId} {...asset.asset} fluid />
                    ))}
                    spaceBetween={20}
                  />

                  <b2x.Div
                    display={{
                      lg: 'block',
                      xs: 'none',
                    }}
                  >
                    <SliderButton
                      className="position-absolute"
                      direction="right"
                      innerRef={navigationNextElRef}
                      size="large"
                      style={{ zIndex: 10 }}
                    />
                    <SliderButton
                      className="position-absolute"
                      direction="left"
                      innerRef={navigationPrevElRef}
                      size="large"
                      style={{ zIndex: 10 }}
                    />
                  </b2x.Div>
                </>
              )}
            </b2x.SwiperContext>
          </b2x.Div>
        </b2x.Col>
      </b2x.Row>
    </b2x.Div>
  );
};

interface GridSectionProps extends GridSectionContentType {}

const GridSection = ({ columns, containerType, mobileBehavior, spaceBetweenColumns }: GridSectionProps) => {
  const columnsCount = columns?.length || 0;

  return (
    <div
      className={classNames('grid-section overflow-hidden', {
        'container-fluid': containerType === 'fluid',
        'container-xxl': containerType === 'standard',
      })}
    >
      {mobileBehavior === 'slider' ? (
        <b2x.Div className="position-relative">
          <b2x.SwiperContext>
            {({ navigationNextElRef, navigationPrevElRef }) => (
              <>
                <b2x.SwiperFromContent
                  breakpoints={{
                    lg: { slidesPerView: columnsCount },
                    sm: { slidesPerView: columnsCount > 2 ? 2.2 : columnsCount },
                    xs: { slidesPerView: columnsCount > 1 ? 1.2 : columnsCount },
                  }}
                  dir="rtl"
                  navigation={{ custom: true }}
                  slides={columns?.map((column) => (
                    <div key={column.contentSectionId}>
                      <GridSectionColumn {...column} />
                    </div>
                  ))}
                  spaceBetween={spaceBetweenToGapConverter(spaceBetweenColumns) * 6}
                />
                <b2x.Div
                  display={{
                    lg: 'block',
                    xs: 'none',
                  }}
                >
                  <SliderButton
                    className="bg-white rounded-circle"
                    direction="right"
                    innerRef={navigationNextElRef}
                    size="small"
                    style={{ zIndex: 10 }}
                  />
                  <SliderButton
                    className="bg-white rounded-circle"
                    direction="left"
                    innerRef={navigationPrevElRef}
                    size="small"
                    style={{ zIndex: 10 }}
                  />
                </b2x.Div>
              </>
            )}
          </b2x.SwiperContext>
        </b2x.Div>
      ) : (
        <b2x.Row gap={spaceBetweenToGapConverter(spaceBetweenColumns)}>
          {columns?.map((column) => {
            if (!column.size) {
              return null;
            }

            const size = gridSectionColumnSizeConverter(column.size);
            const mobileSize = mobileBehavior === 'inColumn' ? 12 : size;

            return (
              <b2x.Col
                key={column.contentSectionId}
                size={{
                  lg: size,
                  xs: mobileSize,
                }}
              >
                <GridSectionColumn {...column} />
              </b2x.Col>
            );
          })}
        </b2x.Row>
      )}
    </div>
  );
};

interface GridSectionColumnProps extends GridSectionColumnContentType {}

const GridSectionColumn = ({ spaceBetweenBlocks, verticalBlocks }: GridSectionColumnProps) => {
  return (
    <b2x.Div display="flex" flexDirection="column" gap={spaceBetweenToGapConverter(spaceBetweenBlocks)}>
      {verticalBlocks?.map((block) => {
        let blockComponent = null;
        if (block.blockType === 'text') {
          blockComponent = (
            <b2x.H3 className={`text-${block.textBlock?.textColor}`}>{b2x.formatHtml(block.textBlock?.text)}</b2x.H3>
          );
        }

        if (block.blockType === 'asset') {
          blockComponent = (
            <b2x.AssetV1
              className={classNames({
                'w-100': block.assetBlock?.fullWidth,
              })}
              key={block.contentSectionId}
              {...block.assetBlock?.asset}
              fluid
            />
          );
        }

        if (block.blockType === '3d' && block['3dBlock']?.id) {
          blockComponent = <b2x.Zakeke3DViewer key={block.contentSectionId} productId={block['3dBlock'].id} />;
        }

        if (block.blockType === 'cta') {
          blockComponent = (
            <div
              className={classNames('cta-block', {
                'full-width': block.ctaBlock?.fullWidth,
              })}
            >
              <b2x.CtaFromContent key={block.contentSectionId} {...block.ctaBlock?.cta} />
            </div>
          );
        }

        return (
          <b2x.Div
            className={classNames({
              'w-100': !block.alignment,
            })}
            key={block.contentSectionId}
            textAlign={{ xs: block.alignment }}
          >
            {blockComponent}
          </b2x.Div>
        );
      })}
    </b2x.Div>
  );
};

interface ProductsSectionProps extends ProductsSectionContentType {}

const ProductsSection = ({ ids, overlay }: ProductsSectionProps) => {
  const style = `
  .section-products {
    .product-tile {
      .product-image:before {
        background-image: url(${overlay?.image?.url});
        ${overlay?.position === 'start' ? 'left: 25px;' : overlay?.position === 'end' ? 'right: 25px;' : ''}
      }
    }
  }
  `;
  return (
    <b2x.Div className="section-products">
      <style>{style}</style>
      <div className="container-fluid px-lg-0 overflow-hidden">
        {ids?.length && ids.length > 0 && (
          <b2x.ProductsByIds ids={ids.map(({ id }) => id)}>
            {(products) => (
              <b2x.Listing name="Page builder v1" products={products}>
                <b2x.ProductsTiles
                  enableExtraInfo
                  populate={b2x.appConfig.api?.productTilePopulate}
                  products={products?.map((product) => ({ product })) ?? []}
                  productsPerRow={{ lg: 4, xs: 2 }}
                />
              </b2x.Listing>
            )}
          </b2x.ProductsByIds>
        )}
      </div>
    </b2x.Div>
  );
};

interface ThreeDimensionalSliderProps extends ThreeDimensionalSliderContentType {}

const ThreeDimensionalSlider = ({ slides, textColor }: ThreeDimensionalSliderProps) => {
  const initialSlide = 1;

  const [firstSwiper, setFirstSwiper] = React.useState<b2x.SwiperClass>();
  const [secondSwiper, setsecondSwiper] = React.useState<b2x.SwiperClass>();

  return (
    <b2x.Div style={{ color: textColor }}>
      <b2x.Container className="section-3d my-5 py-5 px-0 px-lg-3">
        <b2x.SwiperContext>
          {({ swiper }) => (
            <b2x.Swiper
              navigation={{ custom: true }}
              {...slides}
              breakpoints={{
                lg: {
                  creativeEffect: {
                    next: { translate: ['100%', 0, 0] },
                    prev: { translate: ['-100%', 0, 0] },
                  },
                  slidesPerView: 3,
                },
              }}
              centeredSlides
              controller={{ control: secondSwiper }}
              creativeEffect={{
                limitProgress: 2,
                next: { scale: 0.6, translate: ['80%', 0, 0] },
                prev: { scale: 0.6, translate: ['-80%', 0, 0] },
              }}
              effect="creative"
              initialSlide={initialSlide}
              onSwiper={setFirstSwiper}
              slides={slides?.map(({ backgroundImage, contentSectionId, id, name }) =>
                // eslint-disable-next-line react/display-name
                ({ isActive }) => (
                  <b2x.Div display={{ xs: 'flex' }} justifyContent="center">
                    <b2x.Div className="text-center" key={contentSectionId}>
                      <span
                        className={classNames('h4 px-1 px-lg-3 py-2 d-inline-block')}
                        style={{
                          border: `solid 1px ${textColor}`,
                          borderColor: isActive ? textColor : 'transparent',
                        }}
                      >
                        {isActive ? (
                          <>
                            {'[ \u00A0'}
                            <span className="d-none d-md-inline-block">{'\u00A0'}</span>
                            {name}
                            <span className="d-none d-md-inline-block">{'\u00A0'}</span>
                            {'\u00A0 ]'}
                          </>
                        ) : (
                          name
                        )}
                      </span>
                      {id && (
                        <b2x.Div
                          className={classNames('position-relative', {
                            'px-5': isActive,
                          })}
                        >
                          <b2x.Zakeke3DViewer backgroundImage={backgroundImage?.url} productId={id} />
                          {isActive && (
                            <>
                              {!swiper?.isBeginning && (
                                <Button
                                  className="position-absolute start-0 top-0 bottom-0 my-auto rounded-circle bg-transparent border border-danger p-2"
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onClick={() => swiper?.slidePrev()}
                                  style={{ height: 'fit-content' }}
                                >
                                  <Icon className="text-danger" name="small-arrow-left" size={20} />
                                </Button>
                              )}
                              {!swiper?.isEnd && (
                                <Button
                                  className="position-absolute end-0 top-0 bottom-0 my-auto rounded-circle bg-transparent border border-danger p-2"
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onClick={() => swiper?.slideNext()}
                                  style={{ height: 'fit-content' }}
                                >
                                  <Icon className="text-danger" name="small-arrow-right" size={20} />
                                </Button>
                              )}
                            </>
                          )}
                        </b2x.Div>
                      )}
                    </b2x.Div>
                  </b2x.Div>
                )
              )}
              slidesPerView={1.3}
            />
          )}
        </b2x.SwiperContext>
        <b2x.SwiperContext>
          <b2x.Swiper
            {...slides}
            centeredSlides
            className="my-5"
            controller={{ control: firstSwiper }}
            initialSlide={initialSlide}
            onSwiper={setsecondSwiper}
            slides={slides?.map(({ contentSectionId, icons }) => (
              <b2x.Div className="w-lg-50 mx-auto gap-4 px-4" display="flex" key={contentSectionId}>
                {icons?.map((icon) => (
                  <b2x.Div key={icon.contentSectionId} style={{ flex: 1 }}>
                    <div className="mx-auto" style={{ maxWidth: 50, minWidth: 50 }}>
                      <b2x.NotResponsiveImageFromContent {...icon.icon} fluid />
                    </div>
                    <b2x.Div className="text-center">{icon.label}</b2x.Div>
                  </b2x.Div>
                ))}
              </b2x.Div>
            ))}
          />
        </b2x.SwiperContext>
      </b2x.Container>
    </b2x.Div>
  );
};
