import './LandingTemplateBPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../Button';
import { LandingTemplateBPageContentSection, LandingTemplateBPageContentType } from '../contentTypes';
import { Icon } from '../Icon';
import { SliderButton } from '../SliderButton';
import { Page } from './Page';

const [LandingTemplateBPageContextProvider, useLandingTemplateBPageContext] = b2x.createContext<{
  fontFamily?: LandingTemplateBPageContentType['fontFamily'];
  fontWeight?: LandingTemplateBPageContentType['fontWeight'];
  textColor?: LandingTemplateBPageContentType['textColor'];
}>('LandingTemplateBPageContext');

export interface LandingTemplateBPageProps {}

export const LandingTemplateBPage = (props: LandingTemplateBPageProps) => {
  const page = b2x.usePage<LandingTemplateBPageContentType>();

  return (
    <Page
      backgroundColor={page?.content?.body.backgroundColor}
      className="landing-template-b-page"
      noPaddingBottom
      noPaddingTop
      thingsToLoadBeforePageReady={[page]}
    >
      <LandingTemplateBPageContextProvider
        value={{
          fontFamily: page?.content?.body.fontFamily,
          fontWeight: page?.content?.body.fontWeight,
          textColor: page?.content?.body.textColor,
        }}
      >
        <div style={{ color: page?.content?.body.textColor }}>
          {page?.content?.body.sections?.map((section) => (
            <b2x.Div key={section.contentSectionId}>
              {section.component === 'image' && <Image {...section.image} />}
              {section.component === 'video' && <Video {...section.video} />}
              {section.component === 'text' && <Text {...section.text} />}
              {section.component === 'products' && <Products {...section.products} />}
              {section.component === 'gallery' && <Gallery {...section.gallery} />}
              {section.component === 'percentages' && <Percentages {...section.percentages} />}
              {section.component === '3d' && <ThreeDimensional {...section['3d']} />}
            </b2x.Div>
          ))}
        </div>
      </LandingTemplateBPageContextProvider>
    </Page>
  );
};

///////////
// IMAGE //
///////////
const Image = (props: LandingTemplateBPageContentSection['image']) => {
  return (
    <b2x.Div
      alignItems="center"
      className="section-image"
      display="flex"
      justifyContent="center"
      style={{ minHeight: props?.minHeight }}
    >
      <b2x.ImageFromContentV1 {...props?.image} fluid />
    </b2x.Div>
  );
};

///////////
// VIDEO //
///////////
const Video = (props: LandingTemplateBPageContentSection['video']) => (
  <b2x.Div className="section-video" textAlign="center">
    <b2x.VideoFromContentV1 {...props} backgroundColor="black" />
  </b2x.Div>
);

//////////
// TEXT //
//////////
const Text = (props: LandingTemplateBPageContentSection['text']) => {
  const { fontFamily, fontWeight } = useLandingTemplateBPageContext();
  return (
    <b2x.Div
      alignItems="center"
      className={classnames('section-text', { [`fw-${fontWeight}`]: fontWeight })}
      display="flex"
      justifyContent="center"
      style={{
        fontFamily: fontFamily,
      }}
      textAlign="center"
    >
      <div className="px-3 px-lg-5 py-5 m-0 m-lg-5 text-uppercase" style={{ letterSpacing: 5, maxWidth: 900 }}>
        {props?.content}
      </div>
    </b2x.Div>
  );
};

//////////////
// PRODUCTS //
//////////////
const Products = (props: LandingTemplateBPageContentSection['products']) => {
  const { ids } = props || {};
  const style = `
  .section-products {
    .product-tile {
      .product-image:before {
        background-image: url(${props?.overlay?.image?.url});
        ${
          props?.overlay?.position === 'start'
            ? 'left: 25px;'
            : props?.overlay?.position === 'end'
            ? 'right: 25px;'
            : ''
        }
      }
    }
  }
  `;
  return (
    <b2x.Div className="section-products">
      <style>{style}</style>
      <div className="container-fluid px-lg-0 overflow-hidden">
        {ids?.length && ids.length > 0 && (
          <b2x.ProductsByIds ids={ids.map(({ id }) => id)}>
            {(products) => (
              <b2x.Listing name="Landing template B products" products={products}>
                <b2x.ProductsTiles
                  enableExtraInfo
                  populate={b2x.appConfig.api?.productTilePopulate}
                  products={products?.map((product) => ({ product })) ?? []}
                  productsPerRow={{ lg: 4, xs: 2 }}
                />
              </b2x.Listing>
            )}
          </b2x.ProductsByIds>
        )}
      </div>
    </b2x.Div>
  );
};

/////////////
// GALLERY //
/////////////
const Gallery = (props: LandingTemplateBPageContentSection['gallery']) => {
  return (
    <b2x.Div className="section-gallery" style={{ position: 'relative' }}>
      <b2x.ImageFromContentV1 {...props?.background} className="w-100" fluid />
      <b2x.Div
        alignItems="center"
        display="flex"
        style={{ bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 }}
      >
        <b2x.Div
          style={{
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            padding: 60,
            paddingLeft: 0,
            paddingRight: 30,
            width: '65%',
          }}
        >
          <b2x.Lightbox>
            <b2x.SwiperContext>
              {({ navigationNextElRef, navigationPrevElRef }) => (
                <>
                  <b2x.SwiperFromContent
                    {...props?.slider}
                    breakpoints={{ lg: { slidesPerView: 2.5 } }}
                    dir="rtl"
                    navigation={{ custom: true }}
                    slides={props?.slider?.slides?.map(
                      ({ contentSectionId, image }) =>
                        image?.url && (
                          <div key={contentSectionId}>
                            <b2x.LightboxItem src={image.url}>
                              <b2x.NotResponsiveImageFromContent fluid format={800} {...image} />
                            </b2x.LightboxItem>
                          </div>
                        )
                    )}
                    slidesPerView={1.5}
                    spaceBetween={20}
                  />
                  <SliderButton direction="right" innerRef={navigationPrevElRef} size="large" />
                  <SliderButton direction="left" innerRef={navigationNextElRef} size="large" />
                </>
              )}
            </b2x.SwiperContext>
          </b2x.Lightbox>
        </b2x.Div>
      </b2x.Div>
    </b2x.Div>
  );
};

/////////////////
// PERCENTAGES //
/////////////////
const Percentages = (props: LandingTemplateBPageContentSection['percentages']) => {
  const { fontFamily, fontWeight, textColor } = useLandingTemplateBPageContext();
  const { title } = props || {};
  return (
    <b2x.Container
      className={classnames('section-percentages my-5', { [`fw-${fontWeight}`]: fontWeight })}
      style={{ fontFamily: fontFamily }}
    >
      <b2x.Row cols={{ lg: 2, xs: 1 }}>
        <b2x.Col className="mb-3 mb-lg-0">
          <b2x.Div
            alignItems="center"
            className="h-100"
            display="flex"
            flexDirection="column"
            gap={5}
            justifyContent="center"
            style={{ border: `solid 1px ${textColor}` }}
          >
            {title && <div className="h1">{title}</div>}
            <b2x.Div display="flex" gap={3}>
              {props?.percentages?.map(({ contentSectionId, image, label, value }) => (
                <div key={contentSectionId}>
                  <b2x.Div className="position-relative">
                    <b2x.NotResponsiveImageFromContent {...image} fluid />
                    {value && (
                      <b2x.Div
                        alignItems="center"
                        className="position-absolute top-0 start-0 bottom-0 end-0"
                        display="flex"
                        justifyContent="center"
                      >
                        <span className="h4">{value}%</span>
                      </b2x.Div>
                    )}
                  </b2x.Div>
                  {label && (
                    <b2x.Div marginTop={2} textAlign="center">
                      {label}
                    </b2x.Div>
                  )}
                </div>
              ))}
            </b2x.Div>
          </b2x.Div>
        </b2x.Col>
        <b2x.Col>
          <b2x.AssetV1 {...props?.asset} fluid />
        </b2x.Col>
      </b2x.Row>
    </b2x.Container>
  );
};

////////
// 3D //
////////
const ThreeDimensional = (props: LandingTemplateBPageContentSection['3d']) => {
  const { fontFamily, fontWeight, textColor } = useLandingTemplateBPageContext();
  const initialSlide = 1;

  const [firstSwiper, setFirstSwiper] = React.useState<b2x.SwiperClass>();
  const [secondSwiper, setsecondSwiper] = React.useState<b2x.SwiperClass>();

  return (
    <b2x.Container
      className={classnames('section-3d my-5 py-5 px-0 px-lg-3', { [`fw-${fontWeight}`]: fontWeight })}
      style={{ fontFamily: fontFamily }}
    >
      <b2x.SwiperContext>
        {({ swiper }) => (
          <b2x.Swiper
            navigation={{ custom: true }}
            {...props?.slider}
            breakpoints={{
              lg: {
                creativeEffect: {
                  next: { translate: ['100%', 0, 0] },
                  prev: { translate: ['-100%', 0, 0] },
                },
                slidesPerView: 3,
              },
            }}
            centeredSlides
            controller={{ control: secondSwiper }}
            creativeEffect={{
              limitProgress: 2,
              next: { scale: 0.6, translate: ['80%', 0, 0] },
              prev: { scale: 0.6, translate: ['-80%', 0, 0] },
            }}
            effect="creative"
            initialSlide={initialSlide}
            onSwiper={setFirstSwiper}
            slides={props?.slider?.slides?.map(({ backgroundImage, contentSectionId, icons, id, name }) =>
              // eslint-disable-next-line react/display-name
              ({ isActive }) => (
                <b2x.Div display={{ xs: 'flex' }} justifyContent="center">
                  <b2x.Div className="text-center" key={contentSectionId}>
                    <span
                      className={classnames('h6 px-1 px-lg-3 py-2 d-inline-block')}
                      style={{ border: `solid 1px ${textColor}`, borderColor: isActive ? textColor : 'transparent' }}
                    >
                      {isActive ? (
                        <>
                          {'[ \u00A0'}
                          <span className="d-none d-md-inline-block">{'\u00A0'}</span>
                          {name}
                          <span className="d-none d-md-inline-block">{'\u00A0'}</span>
                          {'\u00A0 ]'}
                        </>
                      ) : (
                        name
                      )}
                    </span>
                    {id && (
                      <b2x.Div
                        className={classnames('position-relative', {
                          'px-5': isActive,
                        })}
                      >
                        <b2x.Zakeke3DViewer backgroundImage={backgroundImage?.url} productId={id} />
                        {isActive && (
                          <>
                            {!swiper?.isBeginning && (
                              <Button
                                className="position-absolute start-0 top-0 bottom-0 my-auto rounded-circle bg-transparent border border-danger p-2"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => swiper?.slidePrev()}
                                style={{ height: 'fit-content' }}
                              >
                                <Icon className="text-danger" name="large-arrow-left" size={20} />
                              </Button>
                            )}
                            {!swiper?.isEnd && (
                              <Button
                                className="position-absolute end-0 top-0 bottom-0 my-auto rounded-circle bg-transparent border border-danger p-2"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => swiper?.slideNext()}
                                style={{ height: 'fit-content' }}
                              >
                                <Icon className="text-danger" name="large-arrow-right" size={20} />
                              </Button>
                            )}
                          </>
                        )}
                      </b2x.Div>
                    )}
                  </b2x.Div>
                </b2x.Div>
              )
            )}
            slidesPerView={1.3}
          />
        )}
      </b2x.SwiperContext>
      <b2x.SwiperContext>
        <b2x.Swiper
          {...props?.slider}
          centeredSlides
          className="my-5"
          controller={{ control: firstSwiper }}
          initialSlide={initialSlide}
          onSwiper={setsecondSwiper}
          slides={props?.slider?.slides?.map(({ contentSectionId, icons }) => (
            <b2x.Div className="w-lg-50 mx-auto gap-4 px-4" display="flex" key={contentSectionId}>
              {icons?.map((icon) => (
                <b2x.Div key={icon.contentSectionId} style={{ flex: 1 }}>
                  <div className="mx-auto" style={{ maxWidth: 50, minWidth: 50 }}>
                    <b2x.NotResponsiveImageFromContent {...icon.icon} fluid />
                  </div>
                  <b2x.Div className="text-center">{icon.label}</b2x.Div>
                </b2x.Div>
              ))}
            </b2x.Div>
          ))}
        />
      </b2x.SwiperContext>
    </b2x.Container>
  );
};
